exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-page-js-content-file-path-opt-build-repo-content-pages-resume-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/content/pages/resume.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-opt-build-repo-content-pages-resume-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-08-24-add-bootstrap-5-to-rails-6-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/2022-08-24-add-bootstrap-5-to-rails-6/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-08-24-add-bootstrap-5-to-rails-6-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-08-28-my-development-setup-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/2022-08-28-my-development-setup/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-08-28-my-development-setup-index-mdx" */),
  "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-09-18-redesigning-my-website-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/content/posts/2022-09-18-redesigning-my-website/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-opt-build-repo-content-posts-2022-09-18-redesigning-my-website-index-mdx" */)
}

